<!-- eslint-disable @angular-eslint/template/cyclomatic-complexity -->
<nav id="fdx-main-nav" class="navbar navbar-expand-md py-0">
    <div class="container-fluid">

        <div class="navbar-brand">
            <a ngbDropdownItem [routerLink]="linkService.feedonomicsLink" aria-label="Navigate to Databases">
                <img [src]="logo" alt="Feedonomics" height="28">
            </a>
        </div>

        <div class="collapse navbar-collapse">
            <ul ngbNav #nav="ngbNav" class="navbar-nav nav-tabs w-100">
                <!-- Dashboards -->
                <li
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.Dashboards) }"
                        [routerLink]="linkService.feedonomicsLink"
                        id="dropdownDashboards"
                        role="button">
                        <fa-icon [icon]="dashboardsTabIcon" size="lg" class="me-1"></fa-icon>
                        Dashboards
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownDashboards">
                        <ng-container *ngIf="!supportOnly">
                            <a ngbDropdownItem [routerLink]="linkService.feedonomicsLink">Feedonomics</a>
                        </ng-container>
                        <ng-container *ngIf="showFeedAlerts">
                            <a ngbDropdownItem [routerLink]="linkService.feedAlertsLink">FeedAlerts</a>
                        </ng-container>
                        <ng-container *ngIf="showFeedStatus">
                            <a ngbDropdownItem [routerLink]="linkService.feedStatusLink">FeedStatus</a>
                        </ng-container>
                        <ng-container *ngIf="showFeedAMP">
                            <a
                                ngbDropdownItem
                                [attr.href]="linkService.feedAMPLink"
                                rel="noopener"
                                referrerpolicy="no-referrer">
                                FeedAMP
                            </a>
                        </ng-container>
                        <ng-container *ngIf="showFeedSupport">
                            <a ngbDropdownItem [routerLink]="feedSupportLink">FeedSupport™</a>
                        </ng-container>
                    </div>
                </li>
                <!-- Imports -->
                <li
                    *ngIf="databaseId"
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [routerLink]="linkService.importsLink"
                        id="dropdownDataSources"
                        role="button"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.DataSources) }">
                        <fa-icon [icon]="dataSourcesTabIcon" size="lg" class="me-1"></fa-icon>
                        Data sources
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownDataSources">
                        <a ngbDropdownItem [routerLink]="linkService.importsLink">Imports</a>
                        <ng-container *ngIf="userHasFeature('automate_build','enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.automateBuildLink">Automate build</a>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.fileMapsLink">File maps</a>
                        <a ngbDropdownItem [routerLink]="linkService.extraFieldsLink">Extra fields</a>
                        <a ngbDropdownItem [routerLink]="linkService.fieldExecutionOrderLink">Field execution order</a>
                        <div class="dropdown-divider"></div>
                        <a ngbDropdownItem [routerLink]="linkService.ftpAccountLink">FTP account</a>
                        <a ngbDropdownItem [routerLink]="linkService.triggersLink">Triggers</a>
                    </div>
                </li>
                <!-- Data -->
                <li
                    *ngIf="databaseId"
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [routerLink]="linkService.dataLink"
                        id="dropdownData"
                        role="button"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.Data) }">
                        <fa-icon [icon]="dataTabIcon" size="lg" class="me-1"></fa-icon>
                        Data
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownData">
                        <a ngbDropdownItem [routerLink]="linkService.dataLink">Data</a>
                        <a ngbDropdownItem [routerLink]="linkService.dataOverridesLink">Data overrides</a>
                        <a ngbDropdownItem [routerLink]="linkService.diffToolLink">Diff tool</a>

                        <!-- AI -->
                        <ng-container *ngIf="userHasFeature('genai','enabled') || userHasFeature('genai_categorization','enabled')">
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-header">
                                AI
                            </div>
                        </ng-container>

                        <ng-container *ngIf="userHasFeature('genai','enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.genAILink">GenAI for creative content</a>
                        </ng-container>

                        <ng-container *ngIf="userHasFeature('genai_categorization','enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.genAICategorizationLink">GenAI categorization</a>
                        </ng-container>

                        <ng-container *ngIf="userHasFeature('genai_attributes','enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.genAIAttributesLink">GenAI attributes</a>
                        </ng-container>

                        <!-- SYNDICATIONS -->
                        <ng-container *ngIf="databaseIsGrouped && fullDbGroupAccess">
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-header">
                                Syndications
                            </div>
                        </ng-container>
                        <ng-container *ngIf="userHasFeature('channel_listings', 'enabled') && (databaseIsGrouped && fullDbGroupAccess)">
                            <a ngbDropdownItem [routerLink]="linkService.channelListingsLink">Channel listings</a>
                        </ng-container>
                        <ng-container *ngIf="databaseIsGrouped && fullDbGroupAccess">
                            <a ngbDropdownItem [routerLink]="linkService.errorOverviewLink">Error overview</a>
                        </ng-container>
                        <ng-container *ngIf="databaseIsGrouped && fullDbGroupAccess">
                            <a ngbDropdownItem [routerLink]="linkService.errorResolutionDataLink">Error resolution data</a>
                        </ng-container>
                        <ng-container *ngIf="databaseIsGrouped && fullDbGroupAccess">
                            <a ngbDropdownItem [routerLink]="linkService.exportRunsLink">Export runs</a>
                        </ng-container>
                    </div>
                </li>
                <!-- Transformers -->
                <li
                    *ngIf="databaseId"
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [routerLink]="linkService.transformersLink"
                        id="dropdownTransformers"
                        role="button"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.Transformers) }">
                        <fa-icon [icon]="transformersTabIcon" size="lg" class="me-1"></fa-icon>
                        Transformers
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownTransformers">
                        <a ngbDropdownItem [routerLink]="linkService.transformersLink">Transformers</a>
                        <a ngbDropdownItem [routerLink]="linkService.bulkTransformersLink">Bulk transformers</a>
                        <a ngbDropdownItem [routerLink]="linkService.mapFieldLink">Map field</a>
                        <a ngbDropdownItem [routerLink]="linkService.categoryTaxonomyLink">Category taxonomy</a>
                        <ng-container *ngIf="accountHasFeature('classifier_info', 'enabled') || userHasFeature('classifier_info', 'enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.feedAILink">FeedAI™</a>
                        </ng-container>
                        <ng-container *ngIf="accountHasFeature('run_classify', 'enabled') || userHasFeature('run_classify', 'enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.classifyTitleLink">Classify title</a>
                        </ng-container>
                        <ng-container *ngIf="accountHasFeature('run_attribution', 'enabled') || userHasFeature('run_attribution', 'enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.attributeDataLink">Attribute data</a>
                        </ng-container>
                    </div>
                </li>
                <!-- Exports Tab -->
                <li
                    *ngIf="databaseId"
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [routerLink]="linkService.exportsLink"
                        id="dropdownExports"
                        role="button"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.Exports) }">
                        <fa-icon [icon]="exportsTabIcon" size="lg" class="me-1"></fa-icon>
                        Exports
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownExports">
                        <ng-container *ngIf="userHasFeature('exports_list', 'enabled')">
                            <a ngbDropdownItem [routerLink]="linkService.exportsListLink">Exports list</a>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.exportsLink">Exports</a>
                        <ng-container *ngIf="isEventSync">
                            <a ngbDropdownItem [routerLink]="linkService.exportTriggersLink">Export triggers</a>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.dataGovernanceLink">Data governance</a>
                        <a ngbDropdownItem [routerLink]="linkService.summaryGovernanceLink">Summary governance</a>
                        <a ngbDropdownItem [routerLink]="linkService.exportRandomSamplingLink">Random sampling</a>
                    </div>
                </li>
                <!-- Reports Tab -->
                <li
                    *ngIf="databaseId"
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item">
                    <a
                        ngbDropdownToggle
                        class="nav-link"
                        [routerLink]="linkService.reportsAuthorizationLink"
                        id="dropdownReports"
                        role="button"
                        [ngClass]="{ 'active': isTabActive(appMenuTab.Reports) }">
                        <fa-icon [icon]="reportsTabIcon" size="lg" class="me-1"></fa-icon>
                        Reports
                    </a>
                    <div ngbDropdownMenu aria-labelledby="dropdownReports">
                        <a ngbDropdownItem [routerLink]="linkService.reportsAuthorizationLink">Authorization</a>
                        <a ngbDropdownItem [routerLink]="linkService.skuReportLink">SKU report</a>
                        <ng-container *ngIf="showFeedGrader">
                            <a ngbDropdownItem [routerLink]="linkService.feedGraderLink">FeedGrader</a>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.feedQALink">FeedQA</a>
                        <ng-container *ngIf="showStoreSummary">
                            <a ngbDropdownItem [routerLink]="linkService.storeSummaryReportLink">Store summary report</a>
                        </ng-container>
                    </div>
                </li>
                <!-- Right nav -->
                <li
                    ngbDropdown
                    fscHoverDropdown
                    [closeDelay]="0"
                    class="nav-item fdx-flex-1 ms-auto mt-auto right-nav-dropdown d-flex justify-content-end">
                    <button
                        ngbDropdownToggle
                        class="unstyled-button ms-auto right-nav-toggle no-icon text-truncate d-flex align-items-center gap-2"
                        type="button"
                        id="dropdownRight">
                        <fa-icon
                            *ngIf="hasPendingInvitations"
                            [icon]="notificationIcon"
                            size="lg"
                            class="fa-shake">
                        </fa-icon>
                        <span class="text-truncate">
                            {{ accountName ? (accountName | truncate: 25) : ''}}
                            <ng-container
                                *ngIf="databaseName">
                                <fa-icon
                                    [icon]="chevronRightIcon"
                                    size="xs"
                                    class="mx-2">
                                </fa-icon>
                                {{ databaseName | truncate: 35 }}
                            </ng-container>
                        </span>
                        <fdx-user-initials
                            [user]="user"
                            [hasNotifications]="hasNotifications">
                        </fdx-user-initials>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownRight" class="dropdown-menu-end">
                        <ng-container
                            *ngIf="databaseId && accountName">
                            <button
                                type="button"
                                class="dropdown-header text-wrap"
                                ngbDropdownItem
                                (mouseenter)="dbAndAccountNameHovered = true"
                                (mouseleave)="dbAndAccountNameHovered = false"
                                (focus)="dbAndAccountNameHovered = true"
                                (blur)="dbAndAccountNameHovered = false"
                                (click)="copyDBAndAccountName()">
                                {{ accountName }}
                                <ng-container
                                    *ngIf="databaseName">
                                    <fa-icon
                                        [icon]="chevronRightIcon"
                                        size="xs"
                                        class="mx-1">
                                    </fa-icon>
                                    {{ databaseName }}
                                    <fa-icon
                                        [icon]="copyIcon"
                                        class="ms-1 copy-names"
                                        [ngClass]="{ 'invisible': !dbAndAccountNameHovered }">
                                    </fa-icon>
                                </ng-container>
                            </button>
                            <div class="dropdown-divider"></div>
                        </ng-container>
                        <ng-container
                            *ngIf="databaseId">
                            <!-- DB -->
                            <div class="dropdown-header">DB</div>
                            <a ngbDropdownItem [routerLink]="linkService.logsLink">Logs</a>
                            <ng-container *ngIf="userHasFeature('new_logs', 'enabled')">
                                <a ngbDropdownItem [routerLink]="linkService.logsNewLink">New logs</a>
                            </ng-container>
                            <a ngbDropdownItem [routerLink]="linkService.alertsLink">Alerts</a>
                            <a ngbDropdownItem [routerLink]="linkService.commentsLink">Comments+</a>
                            <div class="dropdown-divider"></div>

                            <!-- DB GROUP -->
                            <div class="dropdown-header">DB group</div>
                            <a ngbDropdownItem [routerLink]="linkService.vaultLink">Vault</a>
                            <div class="dropdown-divider"></div>
                        </ng-container>
                        <!-- ACCOUNT -->
                        <div
                            ngbDropdown
                            fscHoverDropdown
                            [closeDelay]="0"
                            class="dropstart dropstart-down">
                            <button
                                ngbDropdownToggle
                                ngbDropdownItem
                                type="button"
                                id="dropdownAccount"
                                [ngClass]="{ 'fw-bold': hasNotifications || hasPendingInvitations }">
                                Account <span *ngIf="accountId">(ID: {{accountId}})</span>
                                <fa-icon
                                    *ngIf="hasNotifications || hasPendingInvitations"
                                    [icon]="circleIcon"
                                    size="2xs"
                                    class="text-danger ms-2">
                                </fa-icon>
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownAccount">
                                <a ngbDropdownItem [routerLink]="linkService.accountsLink">
                                    <span>Choose account</span>
                                    <fa-icon
                                        *ngIf="hasPendingInvitations"
                                        [icon]="notificationIcon"
                                        class="ms-2">
                                    </fa-icon>
                                </a>
                                <a ngbDropdownItem [routerLink]="linkService.profileLink">Profile</a>
                                <ng-container *ngIf="accountSelected">
                                    <a ngbDropdownItem [routerLink]="linkService.billingLink">Billing</a>
                                    <a *ngIf="isPrimaryUser" ngbDropdownItem [routerLink]="linkService.userAccessControlLink">User access control</a>
                                    <a
                                        [ngClass]="{ 'fw-bold': hasUnreadReleaseNotes }"
                                        ngbDropdownItem
                                        [routerLink]="linkService.releaseNotesLink">
                                        Release notes
                                        <fa-icon
                                            *ngIf="hasUnreadReleaseNotes"
                                            [icon]="circleIcon"
                                            size="2xs"
                                            class="text-danger ms-2">
                                        </fa-icon>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                        <div class="dropdown-divider"></div>
                        <!-- UTILITIES -->
                        <div class="dropdown-header">Utilities</div>
                        <a ngbDropdownItem [routerLink]="linkService.utilitiesLink">Json to CSV conversion tool</a>
                        <div class="dropdown-divider"></div>
                        <!-- USER -->
                        <div class="dropdown-header">User</div>
                        <div class="dropdown-header">{{ userName }}</div>
                        <ng-container *ngIf="isImpersonatingUser">
                            <div class="dropdown-header">Viewing user: {{ impersonatingUserName }}</div>
                            <button
                                ngbDropdownItem
                                type="button"
                                (click)="removeImpersonatedUser()">
                                Remove viewed user
                            </button>
                        </ng-container>
                        <ng-container *ngIf="(isPrivacyLevelAtLeastAnalyst || isClientDemoView) && !isImpersonatingUser">
                            <button
                                ngbDropdownItem
                                type="button"
                                (click)="toggleClientDemoView()">
                                {{ isClientDemoView ? 'Return to default view' : 'Toggle client demo view' }}
                            </button>
                        </ng-container>
                        <a ngbDropdownItem [routerLink]="linkService.logoutLink" [queryParams]="{'app': 'feedonomics', 'redirect': currentPath}">Logout</a>
                        <div class="dropdown-divider"></div>
                        <!-- HELP -->
                        <div
                            ngbDropdown
                            fscHoverDropdown
                            [closeDelay]="0"
                            class="dropstart dropstart-up">
                            <button
                                ngbDropdownItem
                                ngbDropdownToggle
                                type="button"
                                id="dropdownHelp">
                                Help
                            </button>
                            <div ngbDropdownMenu aria-labelledby="dropdownHelp">
                                <a ngbDropdownItem [routerLink]="linkService.cheatSheetLink">FQL cheat sheet</a>
                                <a ngbDropdownItem [routerLink]="linkService.keyboardShortcutLink">Keyboard shortcuts</a>
                                <a
                                    ngbDropdownItem
                                    [attr.href]="linkService.feedAcademyLink"
                                    target="_blank"
                                    rel="noopener"
                                    referrerpolicy="no-referrer">
                                    FeedAcademy™
                                </a>
                                <a
                                    *ngIf="isPrivacyLevelAtLeastAnalyst"
                                    ngbDropdownItem
                                    [attr.href]="linkService.ahaLink"
                                    target="_blank"
                                    rel="noopener"
                                    referrerpolicy="no-referrer">
                                    Feedback
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>

<fdx-alert *ngIf="isDbDeleted" theme="warning" title="Deleted database!" classes="rounded-0 mb-0">
    This database is not accessible from the databases page because it was deleted on {{dbDeletedAt}}.
</fdx-alert>

<fdx-alert
    *ngIf="isDbPaused"
    theme="warning"
    title="Paused database:"
    [dismissible]="true"
    classes="rounded-0 mb-0">
    This database {{dbPausedAt}}. You can unpause it by navigating to the <a [routerLink]="['/databases']">databases page</a>.
</fdx-alert>
