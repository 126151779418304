import { ExportDestinationDropdownItem } from '@app/exports/models/interfaces/export-destination-dropdown-item.interface';

export const DEFAULT_EXPORT_DESTINATIONS: string[] = [
    'Not applicable',
    'Other'
];

export const COMMON_EXPORT_DESTINATIONS: string[] = [
    'Amazon Seller Central (FBA & FBM)',
    'Amazon Seller Central (FBA)',
    'Amazon Seller Central (FBM)',
    'Criteo',
    'eBay',
    'Google Dynamic Text Ads',
    'Google Local Inventory Ads',
    'Google Manufacturer Center',
    'Google Regional Availability and Pricing',
    'Google Shopping Ads',
    'Google Vehicle Ads',
    'Meta - Local Inventory Ads',
    'Meta - Facebook Automotive Inventory Ads',
    'Meta - Facebook/Instagram Ads',
    'Meta - Facebook/Instagram Shops',
    'Microsoft - Local Inventory Ads',
    'Microsoft Ads',
    'Microsoft Automotive Ads',
    'Pinterest',
    'Snapchat',
    'Target Plus',
    'TikTok Ads',
    'TikTok Shop',
    'Walmart'
];

export const GENERAL_EXPORT_DESTINATIONS: string[] = [
    '1-800-Flowers.com',
    '4Cite',
    'About You',
    'Adacado',
    'Adcrowd',
    'Adobe Target',
    'AdRoll',
    'Affiliate Future',
    'Affilinet',
    'Afound',
    'Agorapulse',
    'Ahlens',
    'Alltricks',
    'Altitude Sports',
    'Amazon Local Selling',
    'Ammobuy.com',
    'AmmoNow',
    'AmmoSeek',
    'Ashley HomeStore',
    'Atlas For Men',
    'Attentive',
    'Auchan',
    'Aurgi',
    'AvantLink',
    'AWIN',
    'B&Q',
    'B2W Digital',
    'Basketful',
    'BazaarVoice',
    'Bazar de l\'Hôtel de Ville',
    'Belboon',
    'Belk',
    'Beslist.nl',
    'Best Buy CA',
    'Better Homes & Gardens',
    'BigCommerce',
    'Billiger.de',
    'Blokker',
    'Bloomingdales',
    'Bloomreach',
    'Bob\'s Discount Furniture',
    'Bol.com',
    'Boulanger',
    'Brico Privé',
    'Bricomarche',
    'Bronto',
    'Bulevip',
    'BulkAmmo',
    'Bunnings',
    'Campadre',
    'Carethy',
    'Carrefour',
    'Casa del Libro',
    'Catch',
    'Ceneo',
    'Cenowarka',
    'Certona',
    'ChannelAdvisor',
    'Choozen',
    'CJ Affiliate',
    'Coin',
    'Commerce Connector',
    'Comparer.be',
    'Conforama',
    'Connexity',
    'Coppel',
    'Coravin',
    'Cordial',
    'Creatable',
    'Creavea',
    'Crobox',
    'Crowdfox',
    'Cultura',
    'Curalate',
    'Daisycon',
    'Darty',
    'Debenhems',
    'Decathlon',
    'Destini',
    'Dia & Co',
    'Digi-Key',
    'Domodi',
    'Doordash',
    'Dooyoo',
    'Dormify',
    'Dotdash Meredith',
    'Douglas',
    'Dynamic Yield',
    'eBay Commerce Network',
    'eBay Motors',
    'El Corte Ingles',
    'Elkjop',
    'EmarSys',
    'Empik',
    'Empreintes',
    'Eobuwie',
    'Eprice',
    'Etsy',
    'Express',
    'Familyblend',
    'Fanatics',
    'Fancy',
    'Fashionchick',
    'FeelUnique',
    'FindGift.com',
    'Fnac',
    'Fonq',
    'Fruugo',
    'Fyndiq',
    'Galeries Lafayette',
    'Geizhals',
    'Getprice',
    'Giant Eagle',
    'Gilt',
    'Gun Coyote',
    'Gun Made',
    'Gun.Deals',
    'GunCritic.com',
    'High Cap Deals',
    'Holt Renfrew',
    'Houzz',
    'iAdvize',
    'Impact Radius',
    'Increasingly',
    'Instacart',
    'Kenshoo',
    'Kijiji',
    'Klarna',
    'Klaviyo',
    'Kogan',
    'Kohl\'s',
    'Kroger',
    'La Redoute',
    'Lily AI',
    'LinkConnector',
    'Lisa',
    'Listrak',
    'LiveClicker',
    'Locally',
    'Lyst',
    'Macy\'s',
    'Mercado Libre',
    'MikMak',
    'Moloco',
    'Monetate',
    'Moveable Ink',
    'Narrativ',
    'Narvar',
    'NewEgg',
    'Nextag',
    'Nordstrom',
    'Oracle Responsys',
    'OrderGroove',
    'Oriental Trading',
    'Overstock',
    'Partnerize',
    'Parts Town',
    'Peapod',
    'Pepperjam',
    'Pricegrabber',
    'Pricesearcher',
    'Pricespider',
    'Printemps',
    'ProductsUp',
    'Rakuten Advertising',
    'Raptive',
    'Reddit',
    'Refersion',
    'Reflektion',
    'Reitmans',
    'Sailthru',
    'Saks Fifth Avenue',
    'SalonCentric',
    'Salsify',
    'Searchspring',
    'ShareAsale',
    'Shein',
    'Shop Premium Outlets',
    'Shopify',
    'ShopStyle',
    'SIMON Shop Premium Outlets (SPO)',
    'Smart Commerce',
    'Social Native',
    'Sociomantic',
    'StitcherAds',
    'Stylitics',
    'SundaySky',
    'The Bay',
    'Tiendamia',
    'Trademe',
    'Trustpilot',
    'Uber',
    'Urban Outfitters',
    'Verishop',
    'Vivino',
    'Walmart Localfinds',
    'Walmart WFS',
    'WhatCounts',
    'WikiArms',
    'Wish',
    'Wunderkind',
    'X',
    'Yahoo Advertising',
    'Yotpo',
    'YouTube Shopping',
    'Zalando',
    'ZipRecruiter'
];

export const EXPORT_DESTINATION_DROPDOWN_VALUES: ExportDestinationDropdownItem[] = [
    ...DEFAULT_EXPORT_DESTINATIONS.map((destination) => ({
        section: 'default',
        value: destination
    })), 
    ...COMMON_EXPORT_DESTINATIONS.map((destination) => ({
        section: 'mostCommon',
        value: destination
    })), 
    ...GENERAL_EXPORT_DESTINATIONS.map((destination) => ({
        section: 'general',
        value: destination
    }))];
