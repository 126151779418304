import { UIRouterState } from '@ajs/ajs-upgraded-providers';
import { UserModel } from '@ajs/models/user/UserModel';
import AppStateService from '@ajs/services/AppStateService';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppMenuTab } from '@app/core/models/enums/app-menu-tab.enum';
import { WINDOW } from '@app/core/providers/window.provider';
import { FdxBlockUIService } from '@app/core/services/fdx-block-ui.service';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { LinkService } from '@app/core/services/link.service';
import { Grant } from '@app/user/models/user-data.model';
import { UserSettings } from '@app/user/models/user-settings.model';
import { InvitationsService } from '@app/user/services/invitations.service';
import { UserSettingsResponse } from '@app/user/services/responses/user-settings.response';
import { UserDataService } from '@app/user/services/user-data.service';
import { IconDefinition, faChevronRight, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { StateService } from '@uirouter/angularjs';
import { EMPTY, Subject, catchError, finalize, takeUntil, tap } from 'rxjs';

@Component({
    selector: 'fdx-abstract-app-menu-component',
    template: ''
})
export abstract class AbstractAppMenuComponent implements OnInit, OnDestroy {

    protected abstract get logo(): string;

    protected appMenuTab: typeof AppMenuTab = AppMenuTab;
    protected readonly chevronRightIcon: IconDefinition = faChevronRight;
    protected readonly copyIcon: IconDefinition = faCopy;

    protected dbAndAccountNameHovered: boolean = false;

    protected readonly unsubscribe$: Subject<void> = new Subject<void>();

    protected get accountId(): number | null {
        return this.appStateService.getAccountId();
    }

    protected get accountName(): string | null {
        return this.appStateService.getAccount()?.account_name;
    }

    protected get databaseName(): string {
        if (this.$state.current?.data?.hideMenuDatabaseName === true) {
            return '';
        }
        return this.appStateService.getDatabaseName();
    }

    protected get hasPendingInvitations(): boolean {
        return this.invitationsService.hasPendingInvitations;
    }

    protected get user(): UserModel | null {
        return this.appStateService.getUser();
    }

    protected get userName(): string {
        return this.appStateService.getUser()?.user_name;
    }

    protected get isClientDemoView(): boolean {
        return this.user?.getSetting('user.demo_client_view');
    }

    protected get isImpersonatingUser(): boolean {
        return this.impersonateUserService.getUser() !== null;
    }

    protected get isPrivacyLevelAtLeastAnalyst(): boolean {
        return this.appStateService.isPrivacyLevelAtLeastAnalyst();
    }

    protected get isPrivacyLevelAtLeastAdmin(): boolean {
        return this.appStateService.isPrivacyLevelAtLeastAdmin();
    }

    constructor(
        @Inject(UIRouterState) protected readonly $state: StateService,   // Temporary for use with Ebay Selection and User Access Control pages being on AngularJS router
        protected readonly linkService: LinkService,
        protected readonly appStateService: AppStateService,
        protected readonly fdxBlockUIService: FdxBlockUIService,
        protected readonly fdxUI: FdxUI,
        protected readonly fdxUtils: FdxUtilsService,
        protected readonly impersonateUserService: ImpersonateUserService,   // TODO: Upgrade to Angular
        protected readonly invitationsService: InvitationsService,
        protected readonly userDataService: UserDataService,
        protected readonly router: Router,
        @Inject(WINDOW) protected readonly window: Window
    ) {
    }

    ngOnInit(): void {
        this.invitationsService.loadInvitations();
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    protected isTabActive(tab: AppMenuTab): boolean {
        return tab === this.appStateService.activeTab;
    }

    protected reloadAfterClientDemoViewToggle(): void {
        this.window.location.reload();
    }

    protected toggleClientDemoView(): void {
        const settings = this.user?.getSettings();

        if ('user.demo_client_view' in settings) {
            const newSettings: UserSettings = {
                ...settings,
                'user.demo_client_view': !settings['user.demo_client_view']
            };

            this.fdxBlockUIService.start();

            this.userDataService.updateSettings(newSettings).pipe(
                tap((response: UserSettingsResponse) => {
                    this.user?.updateSettings(response.data);
                    this.reloadAfterClientDemoViewToggle();
                }),
                catchError((error: unknown) => {
                    if (error instanceof HttpErrorResponse && typeof error.error === 'string') {
                        this.fdxUI.showToastError(error.error);
                    }
                    return EMPTY;
                }),
                finalize(() => this.fdxBlockUIService.stop()),
                takeUntil(this.unsubscribe$)
            ).subscribe();
        }
    }

    protected hasGrant(grant: Grant): boolean {
        return this.user?.hasGrant(grant, this.appStateService.getAccountId());
    }

    protected accountHasFeature(feature: string, value: string): boolean {
        return this.appStateService.getAccount()?.hasFeature(feature, value);
    }

    protected userHasFeature(feature: string, value: string): boolean {
        return this.user?.hasFeature(feature, value);
    }

    protected copyDBAndAccountName(): void {
        let text: string = this.accountName;
        if (this.databaseName) {
            text = `${text} > ${this.databaseName}`;
        }

        this.fdxUtils.copyTextToClipboard(text)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                complete: () => {
                    this.fdxUI.showToastSuccess('Copied to clipboard');
                },
                error: () => {
                    this.fdxUI.showToastError('Failed to copy to clipboard');
                }
            });
    }
}
