export type PresetOption = {
    name: string;
    group: string;
    filePath: string;
    isVisible: boolean;
}

export const presetOptions: PresetOption[] = [
    {
        name: 'Custom / Choose a Template',
        group: '',
        filePath: '/app/exports//app/exports/export_templates/custom/custom_-_choose_a_template.json',
        isVisible: true,
    },
    {
        name: 'All',
        group: '',
        filePath: '/app/exports/export_templates/all/all.json',
        isVisible: true,
    },
    {
        name: 'Template String',
        group: '',
        filePath: 'template_string',
        isVisible: true,
    },
    {
        name: 'Rakuten - GSP',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_rad_gsp.json',
        isVisible: false, // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Full',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_full.json',
        isVisible: false, // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Delta',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_delta.json',
        isVisible: false, // Access determined by the following templates: 'rakuten_export_templates', 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Full - Test',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_full_test.json',
        isVisible: false, // Access determined by the following templates: 'rakuten_test_export_templates'
    },
    {
        name: 'Rakuten - Publisher Delta - Test',
        group: 'Rakuten Advertising',
        filePath: '/app/exports/export_templates/rakuten/rakuten_publisher_delta_test.json',
        isVisible: false, // Access determined by the following templates: 'rakuten_test_export_templates'
    },
    {
        name: 'Google Shopping',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping.json',
        isVisible: true,
    },
    {
        name: 'Bing Shopping',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/bing/bing_shopping.json',
        isVisible: true,
    },
    {
        name: 'Google Manufacturer Center',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_manufacturer_center.json',
        isVisible: true,
    },
    {
        name: 'Google Shopping Content API - Lite',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping_content_api_lite.json',
        isVisible: true,
    },
    {
        name: 'Google Shopping Content API - Full',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/google_shopping_content_api.json',
        isVisible: true,
    },
    {
        name: 'Regional Availablity Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/regional_availability_feed_content_api.json',
        isVisible: true,
    },
    {
        name: 'Promotions Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/promotions_feed_content_api.json',
        isVisible: true,
    },
    {
        name: 'Region Feed Google Content API',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/google/region_feed_content_api.json',
        isVisible: true,
    },
    {
        name: 'Shopzilla',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/shopzilla/shopzilla.json',
        isVisible: true,
    },
    {
        name: 'Pricesearcher',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/pricesearcher/pricesearcher.json',
        isVisible: true,
    },
    {
        name: 'Become',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/become/become.json',
        isVisible: true,
    },
    {
        name: 'Nextag',
        group: 'Comparison Shopping Engine',
        filePath: '/app/exports/export_templates/nextag/nextag.json',
        isVisible: true,
    },
    {
        name: 'Google Dynamic Text Ad Campaigns',
        group: 'Dynamic Text Ads',
        filePath: '/app/exports/export_templates/google/google_dynamic_text_ad_campaigns.json',
        isVisible: true,
    },
    {
        name: 'Facebook API DPA',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_api_dpa.json',
        isVisible: true,
    },
    {
        name: 'Facebook Dynamic Product Ads',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_dynamic_product_ads.json',
        isVisible: true,
    },
    {
        name: 'Facebook Automotive Catalog',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/facebook/facebook_automotive.json',
        isVisible: true,
    },
    {
        name: 'Criteo',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/criteoremaketing/crit_template.json',
        isVisible: true,
    },
    {
        name: 'Snap',
        group: 'Remarketing',
        filePath: '/app/exports/export_templates/snap/snap.json',
        isVisible: true,
    },
    {
        name: 'Pinterest',
        group: 'Social',
        filePath: '/app/exports/export_templates/pinterest/pinterest.json',
        isVisible: true,
    },
    {
        name: 'Bazaar Voice',
        group: 'Reviews',
        filePath: '/app/exports/export_templates/bazaar/bazaar_voice.json',
        isVisible: true,
    },
    {
        name: 'Olapic',
        group: 'Social',
        filePath: '/app/exports/export_templates/olapic/olapic.json',
        isVisible: true,
    },
    {
        name: 'TikTok',
        group: 'Social',
        filePath: '/app/exports/export_templates/tiktok/tiktok.json',
        isVisible: true,
    },
    {
        name: 'LinkConnector',
        group: 'Affiliate',
        filePath: '/app/exports/export_templates/linkconnector/linkconnector.json',
        isVisible: true,
    },
    {
        name: 'Pronto',
        group: 'Affiliate',
        filePath: '/app/exports/export_templates/pronto/pronto.json',
        isVisible: true,
    },
    {
        name: 'ShareASale',
        group: 'Affiliate',
        filePath: '/app/exports/export_templates/shareasale/shareasale.json',
        isVisible: true,
    },
    {
        name: 'SearchSpring',
        group: 'On Site Search',
        filePath: '/app/exports/export_templates/searchspring/searchspring.json',
        isVisible: true,
    },
    {
        name: 'Monetate',
        group: 'Personalization',
        filePath: '/app/exports/export_templates/monetate/monetate.json',
        isVisible: true,
    },
    {
        name: 'Movable Ink',
        group: 'Personalization',
        filePath: '/app/exports/export_templates/movable_ink/movable_ink.json',
        isVisible: true,
    },
    {
        name: 'Jobs2Careers',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/jobs2careers/jobs2careers.json',
        isVisible: true,
    },
    {
        name: 'Jopwell',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/jopwell/jopwell.json',
        isVisible: true,
    },
    {
        name: 'Workopolis',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/workopolis/workopolis.json',
        isVisible: true,
    },
    {
        name: 'ZipRecruiter',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter.json',
        isVisible: true,
    },
    {
        name: 'ZipRecruiter (Benefits)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_benefits.json',
        isVisible: true,
    },
    {
        name: 'ZipRecruiter (DSP)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_dsp.json',
        isVisible: true,
    },
    {
        name: 'ZipRecruiter (UKG)',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/ziprecruiter/ziprecruiter_ukg.json',
        isVisible: true,
    },
    {
        name: 'ZipAlerts',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/zipalerts/zipalerts.json',
        isVisible: true,
    },
    {
        name: 'Clickcast',
        group: 'Job Board',
        filePath: '/app/exports/export_templates/clickcast/clickcast.json',
        isVisible: true,
    },
    {
        name: 'Attentive',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/attentive/attentive.json',
        isVisible: true
    },
    {
        name: 'eBay Commerce Network',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_commerce_network.json',
        isVisible: true,
    },
    {
        name: 'eBay Product',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_product.json',
        isVisible: true,
    },
    {
        name: 'eBay Availability',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_availability.json',
        isVisible: true,
    },
    {
        name: 'eBay Order Fulfillment',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_order_fulfillment.json',
        isVisible: true,
    },
    {
        name: 'eBay Watches',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/ebay/ebay_watches.json',
        isVisible: true,
    },
    {
        name: 'Houzz Product Feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/houzz/houzz_product_feed.json',
        isVisible: true,
    },
    {
        name: 'Houzz Inventory Update/Delete feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/houzz/houzz_inventory_update-delete_feed.json',
        isVisible: true,
    },
    {
        name: 'Kijiji API',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_api.json',
        isVisible: true,
    },
    {
        name: 'Kijiji Automotive - Cargigi',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_automotive_cargigi.json',
        isVisible: true,
    },
    {
        name: 'Kijiji E-commerce XML',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_ecom_xml.json',
        isVisible: true,
    },
    {
        name: 'Kijiji Jobs XML',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_jobs_xml.json',
        isVisible: true,
    },
    {
        name: 'Kijiji Jobs Webhook',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/kijiji/kijiji_jobs_webhook.json',
        isVisible: true,
    },
    {
        name: 'Sears Inventory',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/sears/sears_inventory.json',
        isVisible: true,
    },
    {
        name: 'ShopStyle SKU Feed (NDJSON)',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/shopstyle/shopstyle_sku_feed_ndjson.json',
        isVisible: false, // $scope.accountHasFeature('shopstyle_export_template', 'enabled')
    },
    {
        name: 'Vivino',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/vivino/vivino.json',
        isVisible: true,
    },
    {
        name: 'Walmart - Automotive',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/automotive_feed.json',
        isVisible: true,
    },
    {
        name: 'Walmart - Clothing',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/clothing_feed.json',
        isVisible: true,
    },
    {
        name: 'Walmart - Jewelry',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/jewelry_feed.json',
        isVisible: true,
    },

    {
        name: 'Walmart - Inventory Feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/inventory_feed.json',
        isVisible: true,
    },
    {
        name: 'Walmart - Product Feed',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/walmart/product_feed.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Price and Quantity',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/price_and_quantity.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Automotive & Powersports',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/automotive_powersports.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Baby',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/baby.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Beauty',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/beauty.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Camera & Photo',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/camera_photo.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Cell Phones & Accessories',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/cell_phones_accessories.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Clothing, Accessories & Luggage',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/clothing_accessories_luggage.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Collectible Coins',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/collectible_coins.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Computers',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/computers.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Consumer Electronics',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/consumer_electronics.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Entertainment Collectibles',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/entertainment_collectibles.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Furniture',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/furniture.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Gift Cards',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/gift_cards.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Grocery & Gourmet Food',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/grocery_gourmet_food.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Health & Personal Care',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/health_personal_care.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Home, Home Decor, Kitchen & Garden',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/home_home_decor_kitchen_garden.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Home Improvement (Tools)',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/home_improvement_tools.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial & Scientific - Fasteners',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_scientific_-_fasteners.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial & Scientific - Food Service and Janitorial, Sanitation, & Safety',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_scientific_-_food_service_and_janitorial_sanitation_safety.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial & Scientific - Lab & Scientific Supplies',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_scientific_-_lab_scientific_supplies.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial & Scientific - Power Transmission',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_scientific_-_power_transmission.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial & Scientific - Raw Materials',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_scientific_-_raw_materials.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Industrial - Other',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/industrial_-_other.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Jewelry',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/jewelry.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Musical Instruments',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/musical_instruments.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Office Products',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/office_products.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Pet Supplies',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/pet_supplies.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Shoes, Handbags, & Sunglasses',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/shoes_handbags_sunglasses.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Software & Video Games',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/software_video_games.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Sports & Outdoors',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/sports_outdoors.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Sports Collectibles',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/sports_collectibles.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Toys & Games',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/toys_games.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Watches',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/watches.json',
        isVisible: true,
    },
    {
        name: 'Amazon - Inventory Loader',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon/inventory_loader.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Food And Beverages 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/food_and_beverages_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Auto Accessories 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/auto_accessories_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Baby 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/baby_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Beauty 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/beauty_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Clothing 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/clothing_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Health 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/health_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Home 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/home_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Home Improvement 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/home_improvement_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Jewelry',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/jewelry.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Lab Supplies 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/lab_supplies_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Shoes 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/shoes_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon US - Sports 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_us/sports_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Baby 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/baby_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Bookloader 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/bookloader_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Camera and Photo 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/camera_and_photo_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Clothing 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/clothing_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Computers 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/computers_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Consumer Electronics 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/consumer_electronics_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Health 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/health_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Home 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/home_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Lighting 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/lighting_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Music 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/music_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Office 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/office_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Outdoors 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/outdoors_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Software and Video Games 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/software_and_video_games_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Sports 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/sports_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Toys 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/toys_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon AU - Video 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_au/video_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon CA - Auto Accessories 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_ca/auto_accessories_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon CA - Health 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_ca/health_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon CA - Sports 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_ca/sports_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon Germany - Clothing 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_germany/clothing_2019.json',
        isVisible: true,
    },
    {
        name: 'Amazon FR - Clothing 2019',
        group: 'Marketplace',
        filePath: '/app/exports/export_templates/amazon_fr/clothing_2019.json',
        isVisible: true,
    },
];
